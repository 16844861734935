.profata__possibility {
    display: flex;
    flex-direction: row;
    align-items: center;

    padding: 2rem;
    background: var(--color-footer);

    /* ff 3.6+ */
    background:-moz-radial-gradient(circle at 30% -100%, #042c54 25%, rgba(4, 44, 84, 1) 85%, rgba(27, 120, 222, 1) 100%);

    /* safari 5.1+,chrome 10+ */
    background:-webkit-radial-gradient(circle at 30% -100%, #042c54 25%, rgba(4, 44, 84, 1) 85%, rgba(27, 120, 222, 1) 100%);

    /* opera 11.10+ */
    background:-o-radial-gradient(circle at 30% -100%, #042c54 25%, rgba(4, 44, 84, 1) 85%, rgba(27, 120, 222, 1) 100%);

    /* ie 10+ */
    background:-ms-radial-gradient(circle at 30% -100%, #042c54 25%, rgba(4, 44, 84, 1) 85%, rgba(27, 120, 222, 1) 100%);

    /* global 92%+ browsers support */
    background:radial-gradient(circle at 30% -100%, #042c54 25%, rgba(4, 44, 84, 1) 85%, rgba(27, 120, 222, 1) 100%);

    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
}

.profata__possibility-image{
    flex: 1;
    display: flex;
    justify-content: flex-start;
    align-items: center;

    margin-right: 2rem;
}

.profata__possibility-image img {
    width: 100%;
    height: 100%;
}

.profata__possibility-content {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-start;
}

.profata__possibility-content h4 {
    font-family: var(--font-family);
    font-weight: 500;
    font-size: 20px;
    line-height: 30px;
    color: #26d1f7;
}

.profata__possibility-content h4:last-child {
    color: #FF8A71;
}

.profata__possibility-content h1 {
    font-family: var(--font-family);
    font-weight: 800;
    font-size: 34px;
    line-height: 45px;

    margin: 1rem 0;
}

.profata__possibility-content p {
    font-family: var(--font-family);
    font-size: 20px;
    line-height: 30px;
    color: var(--color-text);

    margin-bottom: 2rem;
}

@media screen and (max-width: 950px) {
    .profata__possibility {
        flex-direction: column;
    }

    .profata__possibility-image {
        margin: 1rem 0;
    }

    .profata__possibility-content {
        margin-top: 2rem;
    }
}