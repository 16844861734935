.profata__header{
    display: flex;
    flex-direction: row;
}

.profata__header-content{
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;

    margin-right: 5rem;
}

.profata__header-content h1 {
    font-family: var(--font-family);
    font-weight: 800;
    font-size: 62px;
    line-height: 75px;
    letter-spacing: -0.04em;
}

.profata__header-content p {
    font-family: var(--font-family);
    font-weight: normal;
    font-size: 20px;
    line-height: 27px;
    color: var(--color-text);

    margin-top: 1.5rem;
}

.profata__header-content__input {
    width: 100%;
    margin: 2rem 0 1rem;
    display: flex;
}

.profata__header-content__input input {
    flex: 2;
    width: 100%;
    min-height: 50px;
    font-family: var(--font-family);
    font-weight: 400;
    font-size: 20px;
    line-height: 28px;
    background: var(--color-footer);
    border: 2px solid var(--color-footer);
    padding: 0 1rem;
    outline: none;
    color: #fff;

    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
}

.profata__header-content__input button {
    flex: 0.6;
    width: 100%;
    min-height: 50px;
    background: #FF4820;
    font-family: var(--font-family);
    font-weight: bold;
    font-size: 20px;
    line-height: 27px;
    color: #fff;
    cursor: pointer;
    outline: none;
    padding: 0 1rem;
    border: none;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
}

.profata__header-content__people{
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;

    margin-top: 2rem;
}

.profata__header-content__people img {
    width: 181.8px;
    height: 38px;
}

.profata__header-content__people p {
    margin: 0 0 0 1rem;
    font-family: var(--font-family);
    font-weight: 500;
    font-size: 12px;
    line-height: 38px;
    color: #fff;
    text-align: center;
}

.profata__header-image {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
}

.profata__header-image img {
    width: 100%;
    height: 100%;
}

@media screen and (max-width: 1250px) {
    .profata__header {
        flex-direction: column;
    }

    .profata__header-content {
        margin: 0 0 3rem;
    }
}

@media screen and (max-width: 650px) {
    .profata__header h1 {
        font-size: 48px;
        line-height: 60px;
    }

    .profata__header p {
        font-size: 16px;
        line-height: 24px;
    }

    .profata__header-content__people {
        flex-direction: column;
    }

    .profata__header-content__people p {
        margin: 0;
    }

    .profata__header-content__input input,
    .profata__header-content__input button {
        font-size: 16px;
        line-height: 24px;
    }
}

@media screen and (max-width: 490px) {
    .profata__header h1 {
        font-size: 36px;
        line-height: 48px;
    }

    .profata__header p {
        font-size: 14px;
        line-height: 24px;
    }

    .profata__header-content__input input,
    .profata__header-content__input button {
        font-size: 12px;
        line-height: 26px;
    }
}