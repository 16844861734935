.profata__blog-container_article {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    background: var(--color-footer);
}

.profata__blog-container_article-image {
    width: 100%;
    height: 100%;
    background: var(--color-footer);
}

.profata__blog-container_article-image img {
    width: 100%;
    height: 100%;
}

.profata__blog-container_article-content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 1rem 2rem;

    height: 100%;
}

.profata__blog-container_article-content p {
    font-family: var(--font-family);
    font-weight: bold;
    font-size: 14px;
    line-height: 35px;
    color: #FFFFFF;
}

.profata__blog-container_article-content h3 {
    font-family: var(--font-family);
    font-weight: 800;
    font-size: 25px;
    line-height: 30px;
    color: #FFFFFF;
    cursor: pointer;
    margin-bottom: 5rem;
}

.profata__blog-container_article-content p:last-child {
    cursor: pointer;
    color: var(--color-subtext);
}

@media screen and (max-width: 600px) {
    .profata__blog-container_article-content h3 {
        font-size: 18px;
        line-height: 25px;
    }
}