.profata__footer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    background: var(--color-footer);

    /* ff 3.6+ */
    background: -moz-radial-gradient(circle at 30% -100%, #042c54 25%, rgba(4, 44, 84, 1) 85%, rgba(27, 120, 222, 1) 100%);

    /* safari 5.1+,chrome 10+ */
    background: -webkit-radial-gradient(circle at 30% -100%, #042c54 25%, rgba(4, 44, 84, 1) 85%, rgba(27, 120, 222, 1) 100%);

    /* opera 11.10+ */
    background: -o-radial-gradient(circle at 30% -100%, #042c54 25%, rgba(4, 44, 84, 1) 85%, rgba(27, 120, 222, 1) 100%);

    /* ie 10+ */
    background: -ms-radial-gradient(circle at 30% -100%, #042c54 25%, rgba(4, 44, 84, 1) 85%, rgba(27, 120, 222, 1) 100%);

    /* global 92%+ browsers support */
    background: radial-gradient(circle at 30% -100%, #042c54 25%, rgba(4, 44, 84, 1) 85%, rgba(27, 120, 222, 1) 100%);

    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
}

.profata__footer-heading {
    width: 100%;
    text-align: center;
    margin-bottom: 3rem;
}

.profata__footer-heading h1 {
    font-family: var(--font-family);
    font-weight: 800;
    font-size: 62px;
    line-height: 75px;
    text-align: center;
    letter-spacing: -0.04em;
}

.profata__footer-button {
    display: flex;
    justify-content: center;
    align-items: center;

    padding: 1rem;
    border: 2px solid #e4ac59;
    text-align: center;
    margin-bottom: 10rem;
    cursor: pointer;
}

.profata__footer-button p {
    box-sizing: border-box;
    font-family: var(--font-family);
    font-size: 18px;
    line-height: 24px;
    color: #e4ac59;

    word-spacing: 2px;
}

.profata__footer-links {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-direction: row;

    width: 100%;
    text-align: left;
}

.profata__footer-links div {
    width: 250px;
    margin: 1rem;
}

.profata__footer-links_logo {
    display: flex;
    flex-direction: column;
}

.profata__footer-links_logo img {
    width: 150px;
    height: 80px;
    margin-right: 2rem;
    margin-bottom: 1rem;
}

.profata__footer-links_logo p {
    font-family: var(--font-family);
    font-size: 18px;
    line-height: 20px;
    color: #e4ac59;
}

.profata__footer-links_div {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}

.profata__footer-links_div h4 {
    font-family: var(--font-family);
    color: #e4ac59;
    font-weight: bold;
    font-size: 18px;
    line-height: 25px;
    margin-bottom: 1rem;
}

.profata__footer-links_div p {
    font-family: var(--font-family);
    color: var(--color-text);
    font-size: 14px;
    line-height: 18px;
    margin: 0.5rem 0;
    cursor: pointer;
}

.profata__footer-copyright {
    margin-top: 2rem;
    text-align: center;
    width: 100%;
}

.profata__footer-copyright p {
    font-family: var(--font-family);
    color: var(--color-text);
    font-size: 14px;
    line-height: 18px;
}

@media screen and (max-width: 950px) {
    .profata__footer-links {
        flex-wrap: wrap;
    }
}

@media screen and (max-width: 850px) {
    .profata__footer-heading h1 {
        font-size: 44px;
        line-height: 50px;
    }
}

@media screen and (max-width: 550px) {
    .profata__footer-heading h1 {
        font-size: 34px;
        line-height: 42px;
    }

    .profata__footer-heading p {
        font-size: 14px;
        line-height: 20px;
    }

    .profata__footer-links div {
        margin: 1rem 0;
    }
}

@media screen and (max-width: 440px) {
    .profata__footer-heading h1 {
        font-size: 27px;
        line-height: 32px;
    }

    .profata__footer-button {
        margin-bottom: 5rem;
    }
}